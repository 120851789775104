import * as React from "react";
import { useHistory } from "hooks";
import { classNames, validatePhone } from "utils";
import {
  Typography,
  Form,
  Input,
  Button,
  Link,
  PhoneInput,
  notification,
} from "components";
import logo from "assets/images/logo-big.png";
import { PhoneValue } from "types";
import { PhoneIcon, EmailIcon } from "./components";
import styles from "./Login.module.css";

interface FormValues {
  email: string;
  phone: PhoneValue;
}

interface LoginProps {
  onSuccess(props: { method: "phone" | "email"; value: string }): void;
}

const Login: React.FC<LoginProps> = ({ onSuccess }) => {
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();

  const onSubmit = (values: FormValues) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      notification.success({ message: "Code sent successfully!" });
      onSuccess(
        values.email.length > 0
          ? { method: "email", value: values.email }
          : { method: "phone", value: values.phone.value }
      );
      history.push("/passcode");
    }, 1500);
  };

  return (
    <div className={styles.wrapper}>
      <img src={logo} alt="Logo" />
      <Typography className={styles.tip}>
        Please enter your cellphone or email address to receive your login
        passcode.
      </Typography>

      <Form
        initialValues={{ email: "", phone: { value: "", country: {} } }}
        className={styles.form}
        layout="vertical"
        onFinish={onSubmit}
      >
        <Form.Item noStyle dependencies={["email"]}>
          {(props) => {
            const disabled = props.getFieldValue("email").length > 0;
            return (
              <Form.Item
                name="phone"
                dependencies={["email"]}
                valuePropName="---"
                rules={[
                  ({ getFieldValue }) => {
                    const emailEntered = getFieldValue("email").length > 0;
                    return emailEntered
                      ? { validator: () => Promise.resolve() }
                      : {
                          validator: () => {
                            const value = getFieldValue("phone");
                            const validationResult = validatePhone(value);
                            if (validationResult) {
                              return Promise.reject(
                                new Error(validationResult)
                              );
                            }
                            return Promise.resolve();
                          },
                        };
                  },
                ]}
              >
                <PhoneInput
                  countryCode="us"
                  prefix={<PhoneIcon />}
                  className={classNames(styles.phone, {
                    [styles.phoneDisabled]: disabled,
                  })}
                  disabled={disabled}
                  onChange={(
                    value: FormValues["phone"]["value"],
                    country: FormValues["phone"]["country"]
                  ) => {
                    props.setFields([
                      { name: "phone", value: { value, country } },
                      { name: "email", touched: false, errors: [] },
                    ]);
                  }}
                  disableDropdown
                />
              </Form.Item>
            );
          }}
        </Form.Item>

        <Typography className={styles.or}>- or -</Typography>

        <Form.Item noStyle dependencies={["email"]}>
          {(props) => {
            const pn = props.getFieldValue("phone");
            const disabled = pn?.country?.dialCode
              ? pn?.value?.replace("+", "").length >
                pn?.country?.dialCode?.length
              : false;
            return (
              <Form.Item
                name="email"
                dependencies={["phone"]}
                rules={[
                  ({ getFieldValue }) => {
                    const phone = getFieldValue("phone");
                    const phoneEntered = phone?.country?.dialCode
                      ? phone?.value?.replace("+", "").length >
                        phone?.country?.dialCode?.length
                      : false;
                    return phoneEntered
                      ? { validator: () => Promise.resolve() }
                      : { type: "email", message: "Invalid email." };
                  },
                ]}
              >
                <Input
                  placeholder="Enter Email"
                  prefix={<EmailIcon />}
                  className={styles.email}
                  disabled={disabled}
                  onChange={(event) => {
                    props.setFields([
                      { name: "email", value: event.target.value },
                      { name: "phone", touched: false, errors: [] },
                    ]);
                  }}
                  size="large"
                />
              </Form.Item>
            );
          }}
        </Form.Item>
        <Button
          className={styles.submit}
          type="primary"
          size="large"
          htmlType="submit"
          loading={loading}
        >
          Log In
        </Button>
      </Form>

      <div className={styles.separator} />
      <Typography size="16" className={styles.help}>
        Need help?
      </Typography>
      <Link to="/">
        <Typography color="pink" size="16">
          Contact Support
        </Typography>
      </Link>
    </div>
  );
};

export default Login;
