import * as React from "react";
import { UploadOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { Typography, Upload, Button } from "components";
import Dots from "../dots";
import styles from "../select-type/SelectType.module.css";

const typeToTitle: any = {
  activate: "Bulk Activation",
  simSwap: "Bulk SIM Swap",
  suspend: "Bulk Suspend",
  restore: "Bulk Restore",
};

interface UploadProps {
  type: string;
  onCancel(): void;
  onSuccess(): void;
}

const UploadStep: React.FC<UploadProps> = ({ type, onCancel, onSuccess }) => {
  return (
    <>
      <Button
        icon={<ArrowLeftOutlined />}
        onClick={onCancel}
        style={{
          top: 24,
          position: "absolute",
          border: "none",
          boxShadow: "none",
          padding: 0,
        }}
      >
        Back
      </Button>
      <Typography size="24" className={styles.text}>
        {typeToTitle[type]}
      </Typography>
      <Typography style={{ marginBottom: 16 }}>
        Please browse and upload the bulk file for this services.
      </Typography>
      <div style={{ marginBottom: 16 }}>
        <Typography
          style={{
            display: "inline-flex",
            alignItems: "center",
            marginRight: 8,
          }}
        >
          Upload the bulk file
        </Typography>
        <Upload>
          <Button type="primary" ghost icon={<UploadOutlined />}>
            UPLOAD
          </Button>
        </Upload>
      </div>

      <Typography>
        You can download the bulk file template for this service &nbsp;
        <a href="http://encoretmo.com/static/bulk-activate-template.xls">
          HERE
        </a>
        .
      </Typography>

      <Button
        type="primary"
        onClick={onSuccess}
        style={{ width: 140, display: "block", marginTop: 16 }}
      >
        Done
      </Button>

      <Dots activeIndex={2} />
    </>
  );
};

export default UploadStep;
