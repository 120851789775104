import * as React from "react";
import Context from "./context";

const AppProvider: React.FC = ({ children }) => {
  const auth = React.useState({ loading: true, loggedIn: false });

  React.useEffect(() => {
    setTimeout(() => {
      const token = localStorage.getItem("token");
      auth[1]({
        loading: false,
        loggedIn: !!token,
      });
    }, 1500);
    // eslint-disable-next-line
  }, []);

  return <Context.Provider value={auth}>{children}</Context.Provider>;
};

export default AppProvider;
