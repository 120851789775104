import * as React from "react";
import {
  CaretDownOutlined,
  CaretUpOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Menu } from "components";
import styles from "./ChooseAccount.module.css";

const ChooseAccount: React.FC = () => {
  const [visible, setVisible] = React.useState(false);

  return (
    <Dropdown
      onVisibleChange={setVisible}
      trigger={["click"]}
      visible={visible}
      overlay={
        <Menu className={styles.menu} selectedKeys={["1"]}>
          <Menu.Item key="1">
            Edward Jones <CheckOutlined />
          </Menu.Item>
          <Menu.Item>Mckesson</Menu.Item>
          <Menu.Item>Modus</Menu.Item>
          <Menu.Item>Johnson Ventures</Menu.Item>
        </Menu>
      }
    >
      <Button type="primary" size="large" onClick={() => setVisible(true)}>
        Choose Account {visible ? <CaretUpOutlined /> : <CaretDownOutlined />}
      </Button>
    </Dropdown>
  );
};

export default ChooseAccount;
