import * as React from "react";
import { Content } from "components";
import { Profile } from "./components";
import styles from "./Account.module.css";

const Account: React.FC = () => {
  return (
    <Content className={styles.wrapper}>
      <Profile />
    </Content>
  );
};

export default Account;
