import * as React from "react";
import ReactCodeInput from "react-code-input";
import { useHistory } from "hooks";
import { Typography, Link, Space, Button, notification } from "components";
import AppContext from "../../../../app/components/app-provider/context";
import styles from "./OTP.module.css";

interface OTPProps {
  method: "phone" | "email";
  value: string;
  onReset(): void;
}

const OTP: React.FC<OTPProps> = ({ value, method, onReset }) => {
  const [resending, setResending] = React.useState(false);
  const onResend = () => {
    setResending(true);
    setTimeout(() => {
      setResending(false);
      notification.success({ message: "Code sent successfully" });
    }, 1000);
  };

  const [, setAuth] = React.useContext(AppContext);

  const history = useHistory();
  const [signin, setSignin] = React.useState(false);
  const onSubmit = () => {
    setSignin(true);
    setTimeout(() => {
      setSignin(false);
      localStorage.setItem("token", "1234");
      history.push("/manage");
      setAuth((prev) => ({ ...prev, loggedIn: true }));
    }, 1000);
  };

  const [code, setCode] = React.useState("");
  const [error, setError] = React.useState("");
  const onChange = (newCode: string) => {
    setCode(newCode);
    if (/\D/.test(newCode)) {
      setError("Code must contain only numbers.");
    } else {
      setError("");
    }
  };

  return (
    <div className={styles.wrapper}>
      <Typography bold size="24">
        Enter Passcode
      </Typography>

      <div className={styles.separator} />

      <Typography size="20">
        Code sent to:{" "}
        <Typography bold size="20" color="pink">
          {value}
        </Typography>
      </Typography>

      <Typography size="16" className={styles.tip}>
        The login passcode has been sent to your{" "}
        {method === "email" ? "email address" : "mobile phone"}. Please enter
        the code below to access the driver portal{" "}
        <Link to="/login" onClick={onReset}>
          or use a different{" "}
          {method === "email" ? "email address" : "mobile phone"}
        </Link>
      </Typography>

      <ReactCodeInput
        // Bad typings.
        // eslint-disable-next-line
        // @ts-ignore
        autoComplete={false}
        fields={6}
        inputMode="numeric"
        className={styles.codeInput}
        value={code}
        onChange={onChange}
      />
      {!!error && (
        <Typography color="red" className={styles.error}>
          {error}
        </Typography>
      )}

      <Space direction="horizontal" size={16} className={styles.controls}>
        <Button
          type="primary"
          ghost
          size="large"
          loading={resending}
          onClick={onResend}
        >
          Resend Code
        </Button>
        <Button
          type="primary"
          size="large"
          loading={signin}
          onClick={onSubmit}
          disabled={!!error || code.length !== 6}
        >
          Sign In
        </Button>
      </Space>
    </div>
  );
};

export default OTP;
