import * as React from "react";
import {
  Typography,
  Form,
  Input,
  Select,
  Space,
  Button,
  Row,
} from "components";
import { Plans, AdditionalServices } from "./components";
import styles from "./Single.module.css";

const Single: React.FC = () => {
  return (
    <>
      <Typography size="24" className={styles.title}>
        Device information
      </Typography>

      <Form layout="horizontal" className={styles.form}>
        <Form.Item label="Device" name="deviceId">
          <Select
            options={[{ label: "SIERRA WIREL SWMC7455L1", value: "1" }]}
          />
        </Form.Item>
        <Form.Item label="IMEI" name="meid">
          <Input />
        </Form.Item>
        <Form.Item label="ICCID" name="simIccid">
          <Input />
        </Form.Item>
        <Form.Item label="Service Area" name="serviceAreaId">
          <Select options={[{ label: "Columbus, OH 614", value: "1" }]} />
        </Form.Item>

        <Space direction="vertical" size={24} style={{ width: "100%" }}>
          <Plans />
          <AdditionalServices />
        </Space>

        <Row justify="end" style={{ marginTop: 24 }}>
          <Space direction="horizontal" size={16}>
            <Button type="primary" ghost>
              Start Over
            </Button>
            <Button type="primary">Activate Device</Button>
          </Space>
        </Row>
      </Form>
    </>
  );
};

export default Single;
