import * as React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ApolloProvider } from "@apollo/client";
import { notification } from "components";
import { apolloClient, store } from "configs";
import { AppProvider, Layout } from "./components";

notification.config({ placement: "topLeft" });

const App: React.FC = () => (
  <BrowserRouter>
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <AppProvider>
          <Layout />
        </AppProvider>
      </Provider>
    </ApolloProvider>
  </BrowserRouter>
);

export default App;
