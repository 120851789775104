import * as React from "react";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { useParams, useSelector } from "hooks";
import { classNames } from "utils";
import { Skeleton, Space, Typography, Row } from "components";
import * as LC from "./components";
import styles from "./Sidebar.module.css";

interface SidebarProps {
  loading: boolean;
  className: string;
}

const Sidebar: React.FC<SidebarProps> = ({ className, loading }) => {
  const { id } = useParams<{ id: string }>();
  const data = useSelector((state) => state.mock[id]);

  return (
    <div className={classNames(className, styles.wrapper)}>
      {loading ? (
        <Skeleton active />
      ) : (
        <Space direction="vertical" size={24} className={styles.row}>
          <Row justify="space-between">
            <Typography size="20">Status:</Typography>
            <span style={{ display: "flex", alignItems: "center" }}>
              {data.status === "active" ? (
                <span className={styles.active}>
                  <CheckCircleFilled />
                </span>
              ) : (
                <span className={styles.inactive}>
                  <CloseCircleFilled />
                </span>
              )}
              <Typography
                size="20"
                style={{ textTransform: "capitalize", marginLeft: 4 }}
              >
                {data.status}
              </Typography>
            </span>
          </Row>

          <Space direction="vertical" size={16} className={styles.row}>
            <LC.ChangeStatus />
            <LC.ChangePlan />
            <LC.ChangeDevice />
            <LC.SwapSIM />
            <LC.ChangeMsisdn />
          </Space>
        </Space>
      )}
    </div>
  );
};

export default Sidebar;
