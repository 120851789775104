import * as React from "react";
import { Skeleton, Radio, Typography, Space } from "components";
import Card from "../../../card";
import styles from "./AvailablePlans.module.css";

interface AvailablePlansProps {
  // removedName: string;
  selectedId: string | null | undefined;
  onSelect(plan: {
    pricePlan: string;
    priceCode: string;
    priceMRC: string;
  }): void;
}

const plans = [
  {
    pricePlan: "Custom IoT IA 250MB Pool",
    priceCode: "PDSLH0529",
    priceMRC: "$3.00",
  },
];

const AvailablePlans: React.FC<AvailablePlansProps> = ({
  // removedName,
  selectedId,
  onSelect,
}) => {
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <Card>
      {loading ? (
        <Skeleton active />
      ) : (
        <Space direction="vertical" size={8} style={{ width: "100%" }}>
          {plans.map((plan) => (
            // eslint-disable-next-line
            <div
              key={plan.pricePlan}
              className={styles.row}
              onClick={() => onSelect(plan)}
            >
              <Radio checked={plan.pricePlan === selectedId} />
              <Typography bold style={{ width: 300 }}>
                {plan.pricePlan}
              </Typography>
              <Typography>{plan.priceCode}</Typography>
              <Typography>{plan.priceMRC}</Typography>
            </div>
          ))}
        </Space>
      )}
    </Card>
  );
};

export default AvailablePlans;
