import * as React from "react";
import { classNames } from "utils";
import styles from "./Content.module.css";

interface ContentProps {
  className?: string;
  children?: React.ReactNode;
}

const Content: React.FC<ContentProps> = ({ className, children }) => {
  return (
    <div className={classNames(styles.wrapper, className)}>{children}</div>
  );
};

export default Content;
