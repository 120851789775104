import * as React from "react";
import { routes } from "configs";
import { useLocation } from "hooks";
import {
  Redirect,
  Content,
  Link,
  Typography,
  RouterSwitch,
  Route,
} from "components";
import { Single, Multiple } from "./components";
import styles from "./Activate.module.css";

const Activate: React.FC = () => {
  const location = useLocation();
  const singleActivationActive = location.pathname.includes(
    routes.activateSingle
  );

  return (
    <Content className={styles.contentWrapper}>
      <div className={styles.wrapper}>
        <div className={styles.inner}>
          <Link to={routes.activateSingle}>
            <Typography
              size="14"
              color={singleActivationActive ? "pink" : "white"}
            >
              Single Activation
            </Typography>
          </Link>
          <div className={styles.divider} />
          <Link to={routes.activateBulk}>
            <Typography
              size="14"
              color={singleActivationActive ? "white" : "pink"}
            >
              Bulk Activation
            </Typography>
          </Link>
        </div>
      </div>
      <div className={styles.content}>
        <RouterSwitch>
          <Route exact path={routes.activateSingle} component={Single} />
          <Route exact path={routes.activateBulk} component={Multiple} />
          <Redirect to={routes.activateSingle} />
        </RouterSwitch>
      </div>
    </Content>
  );
};

export default Activate;
