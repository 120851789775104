import * as React from "react";
import { Space } from "components";
import { classNames } from "utils";
import styles from "./Dots.module.css";

interface DotsProps {
  activeIndex: number;
}

const indexes = [1, 2];

const Dots: React.FC<DotsProps> = ({ activeIndex }) => {
  return (
    <Space direction="horizontal" size={16} className={styles.wrapper}>
      {indexes.map((ind) => (
        <div
          key={ind}
          className={classNames(styles.dot, {
            [styles.active]: ind === activeIndex,
          })}
        />
      ))}
    </Space>
  );
};

export default Dots;
