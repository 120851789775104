import * as React from "react";
import { routes } from "configs";
import { useSelector } from "hooks";
import { classNames } from "utils";
import { Skeleton, Typography, Row, Link } from "components";
import styles from "./GeneralInfo.module.css";

interface GeneralInfoProps {
  className: string;
}

const GeneralInfo: React.FC<GeneralInfoProps> = ({ className }) => {
  const [loading, setLoading] = React.useState(true);

  const data = useSelector((state) => Object.values(state.mock));

  const activeCount = data.filter((item) => item.status === "active").length;
  const suspendedCount = data.filter(
    (item) => item.status === "suspended"
  ).length;

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  return (
    <div className={classNames(styles.wrapper, className)}>
      {loading ? (
        <Skeleton active />
      ) : (
        <>
          <div className={styles.infoBlock}>
            <Row justify="space-between">
              <Typography size="16" bold>
                Company Name
              </Typography>
              <Typography size="16" bold>
                Edward Jones
              </Typography>
            </Row>
            <Row justify="space-between">
              <Typography size="16" bold>
                Account Number
              </Typography>
              <Typography size="16" bold>
                337578469
              </Typography>
            </Row>
          </div>
          <div className={styles.divider} />
          <div className={styles.infoBlock}>
            <Row justify="space-between">
              <Typography size="16">Subscribers Total</Typography>
              <Link to={routes.manage}>
                <Typography className={styles.link} color="pink" size="16">
                  {data.length}
                </Typography>
              </Link>
            </Row>
            <Row justify="space-between">
              <Typography size="16">Active Subscribers Total</Typography>
              <Link to={{ pathname: routes.manage, search: "status=active" }}>
                <Typography className={styles.link} color="pink" size="16">
                  {activeCount}
                </Typography>
              </Link>
            </Row>
            <Row justify="space-between">
              <Typography size="16">Suspended Subscribers Total</Typography>
              <Link
                to={{ pathname: routes.manage, search: "status=suspended" }}
              >
                <Typography className={styles.link} color="pink" size="16">
                  {suspendedCount}
                </Typography>
              </Link>
            </Row>
          </div>
        </>
      )}
    </div>
  );
};

export default GeneralInfo;
