import * as React from "react";
import { InboxOutlined } from "@ant-design/icons";
import { Typography, Upload, Link, Button } from "components";
import styles from "./Multiple.module.css";

const Multiple: React.FC = () => {
  return (
    <>
      <Typography size="24" style={{ display: "block", marginBottom: 24 }}>
        Bulk Activation
      </Typography>
      <Typography style={{ marginBottom: 8 }}>
        Upload a XLS file containing the device ESN / MEID (
        <Link to="/">template</Link>)
      </Typography>
      <Upload.Dragger className={styles.wrapper}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
      </Upload.Dragger>
      <Button type="primary" style={{ marginTop: 16 }}>
        Upload
      </Button>
    </>
  );
};

export default Multiple;
