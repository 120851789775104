import * as React from "react";
import { classNames } from "utils";
import {
  Checkbox,
  Typography,
  Radio,
  Form,
  DatePicker,
  Select,
  Button,
} from "components";
import { Types } from "../../duck";
import { DataFilter } from "./components";
import styles from "./SystemFilter.module.css";

interface SystemFilter {
  dataFilter: Types.FilterState["data"] | null;
  onDataFilterSave(newFilter: Partial<Types.FilterState["data"]>): void;
}

const SystemFilter: React.FC<SystemFilter> = ({
  dataFilter,
  onDataFilterSave,
}) => {
  const [checked, setChecked] = React.useState(false);

  const [openedId, setOpenedId] = React.useState<string | null>(null);
  const [, setSubId] = React.useState<string | null>(null);

  React.useEffect(() => {
    setSubId(null);
  }, [openedId]);

  React.useEffect(() => {
    setOpenedId(null);
    setSubId(null);
  }, [checked]);

  return (
    <Form layout="vertical">
      <div className={styles.checkbox}>
        <Checkbox
          checked={checked}
          onChange={() => setChecked((prev) => !prev)}
        >
          <Typography bold>337578469 - Edward Jones</Typography>
        </Checkbox>
      </div>
      {checked && (
        <Radio.Group
          name="category"
          className={styles.radioBlock}
          value={openedId}
          onChange={(event) => setOpenedId(event.target.value)}
        >
          <Radio value="data">Data</Radio>
          {openedId === "data" && (
            <DataFilter
              className={styles.innerOverlay}
              radioClassName={styles.radioBlock}
              dataFilter={dataFilter}
              onDataFilterSave={onDataFilterSave}
            />
          )}
          <Radio value="attributes">Subscriber Attributes</Radio>
          {openedId === "attributes" && (
            <div className={classNames(styles.innerOverlay, styles.attributes)}>
              <Form.Item label="Filter by status">
                <Select
                  mode="multiple"
                  options={[
                    { label: "Active", value: "1" },
                    { label: "Suspended", value: "2" },
                  ]}
                />
              </Form.Item>

              <Form.Item label="Filter by device">
                <Select
                  mode="multiple"
                  options={[{ label: "SIERRA WIREL SWMC7455L1", value: "1" }]}
                />
              </Form.Item>

              <Form.Item label="Filter by plan">
                <Select
                  mode="multiple"
                  options={[{ label: "Custom IoT IA 250MB Pool", value: "1" }]}
                />
              </Form.Item>

              <Form.Item label="Filter by contract start date">
                <DatePicker.RangePicker style={{ width: "100%" }} />
              </Form.Item>

              <Form.Item label="Filter by contract end date">
                <DatePicker.RangePicker style={{ width: "100%" }} />
              </Form.Item>

              <Button type="primary" size="small" style={{ marginTop: 8 }}>
                Add
              </Button>
            </div>
          )}
        </Radio.Group>
      )}
    </Form>
  );
};

export default SystemFilter;
