import * as React from "react";
import { routes } from "configs";
import { useHistory, useLocation } from "hooks";
import { Menu, Link, Typography } from "components";
import logo from "assets/images/logo.png";
import AppContext from "../../../app-provider/context";
import { Bulk, ChooseAccount } from "./components";
import styles from "./Header.module.css";

const Header: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  const accountActive = location.pathname.includes(routes.account);
  const activeKey = location.pathname.includes(routes.manage) ? "1" : "2";

  const [, setAuth] = React.useContext(AppContext);

  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <img src={logo} alt="T-Mobile logo" className={styles.image} />
        <Menu
          mode="horizontal"
          className={styles.menu}
          selectedKeys={accountActive ? [] : [activeKey]}
        >
          <Menu.Item key="1" onClick={() => history.push(routes.manage)}>
            Manage
          </Menu.Item>
          <Menu.Item key="2" onClick={() => history.push(routes.activate)}>
            Activate
          </Menu.Item>
          <Menu.Item
            key="3"
            onClick={() => window.open("https://emorders.com/", "_blank")}
          >
            Orders
          </Menu.Item>
          <Bulk key="4" />
        </Menu>

        <div className={styles.extra}>
          <Link to={routes.account}>
            <Typography bold size="16" color={accountActive ? "pink" : "black"}>
              Account
            </Typography>
          </Link>

          <Link
            to="/login"
            onClick={() => {
              localStorage.removeItem("token");
              setAuth((prev) => ({ ...prev, loggedIn: false }));
            }}
          >
            <Typography bold size="16">
              Logout
            </Typography>
          </Link>

          <ChooseAccount />
        </div>
      </div>
    </div>
  );
};

export default Header;
