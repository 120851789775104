import { createReducer, ActionType } from "typesafe-actions";
import * as actions from "./actions";
import { Item } from "./types";

const initialData: Record<string, Item> = {
  6149280232: {
    msisdn: "6149280232",
    imei: "089582131902119953",

    pricePlan: "Custom IoT IA 250MB Pool",
    priceCode: "PDSLH0529",
    priceType: "H",
    priceMRC: "$3.00",
    autoPay: "yes",

    status: "active",

    deviceName: "SIERRA WIREL SWMC7455L1",
    deviceManufacturer: "SIERRA WIREL",
    deviceSKU: "SWMC7455L1",
    iccid: "89011202000257284809",

    billingPeriodStart: "10/1/2021",
    billingPeriodEnd: "10/31/2021",
    billingSequenceNumber: "43",

    dataUsage: "132.34 GB",
  },
  6145379962: {
    msisdn: "6145379962",
    imei: "089582131902195544",

    pricePlan: "Custom IoT IA 250MB Pool",
    priceCode: "PDSLH0529",
    priceType: "H",
    priceMRC: "$3.00",
    autoPay: "yes",

    status: "active",

    deviceName: "SIERRA WIREL SWMC7455L1",
    deviceManufacturer: "SIERRA WIREL",
    deviceSKU: "SWMC7455L1",
    iccid: "89011202000261877895",

    billingPeriodStart: "10/1/2021",
    billingPeriodEnd: "10/31/2021",
    billingSequenceNumber: "43",

    dataUsage: "83.16 GB",
  },
  6145699066: {
    msisdn: "6145699066",
    imei: "089582131901643378",

    pricePlan: "Custom IoT IA 250MB Pool",
    priceCode: "PDSLH0529",
    priceType: "H",
    priceMRC: "$3.00",
    autoPay: "yes",

    status: "active",

    deviceName: "SIERRA WIREL SWMC7455L1",
    deviceManufacturer: "SIERRA WIREL",
    deviceSKU: "SWMC7455L1",
    iccid: "89011202000257277670",

    billingPeriodStart: "10/1/2021",
    billingPeriodEnd: "10/31/2021",
    billingSequenceNumber: "43",

    dataUsage: "70.83 GB",
  },
  6145970238: {
    msisdn: "6145970238",
    imei: "089582131902187639",

    pricePlan: "Custom IoT IA 250MB Pool",
    priceCode: "PDSLH0529",
    priceType: "H",
    priceMRC: "$3.00",
    autoPay: "yes",

    status: "active",

    deviceName: "SIERRA WIREL SWMC7455L1",
    deviceManufacturer: "SIERRA WIREL",
    deviceSKU: "SWMC7455L1",
    iccid: "89011202000258518981",

    billingPeriodStart: "10/1/2021",
    billingPeriodEnd: "10/31/2021",
    billingSequenceNumber: "43",

    dataUsage: "70.21 GB",
  },
  6144278336: {
    msisdn: "6144278336",
    imei: "089582131902184017",

    pricePlan: "Custom IoT IA 250MB Pool",
    priceCode: "PDSLH0529",
    priceType: "H",
    priceMRC: "$3.00",
    autoPay: "yes",

    status: "active",

    deviceName: "SIERRA WIREL SWMC7455L1",
    deviceManufacturer: "SIERRA WIREL",
    deviceSKU: "SWMC7455L1",
    iccid: "89011202000261865437",

    billingPeriodStart: "10/1/2021",
    billingPeriodEnd: "10/31/2021",
    billingSequenceNumber: "43",

    dataUsage: "69.23 GB",
  },
  6149287616: {
    msisdn: "6149287616",
    imei: "089582131901676820",

    pricePlan: "Custom IoT IA 250MB Pool",
    priceCode: "PDSLH0529",
    priceType: "H",
    priceMRC: "$3.00",
    autoPay: "yes",

    status: "active",

    deviceName: "SIERRA WIREL SWMC7455L1",
    deviceManufacturer: "SIERRA WIREL",
    deviceSKU: "SWMC7455L1",
    iccid: "89011202000258663092",

    billingPeriodStart: "10/1/2021",
    billingPeriodEnd: "10/31/2021",
    billingSequenceNumber: "43",

    dataUsage: "56.35 GB",
  },
  6144024386: {
    msisdn: "6144024386",
    imei: "089582131902185496",

    pricePlan: "Custom IoT IA 250MB Pool",
    priceCode: "PDSLH0529",
    priceType: "H",
    priceMRC: "$3.00",
    autoPay: "yes",

    status: "active",

    deviceName: "SIERRA WIREL SWMC7455L1",
    deviceManufacturer: "SIERRA WIREL",
    deviceSKU: "SWMC7455L1",
    iccid: "89011202000261865593",

    billingPeriodStart: "10/1/2021",
    billingPeriodEnd: "10/31/2021",
    billingSequenceNumber: "43",

    dataUsage: "54.56 GB",
  },
  6142906098: {
    msisdn: "6142906098",
    imei: "089582131901606209",

    pricePlan: "Custom IoT IA 250MB Pool",
    priceCode: "PDSLH0529",
    priceType: "H",
    priceMRC: "$3.00",
    autoPay: "yes",

    status: "active",

    deviceName: "SIERRA WIREL SWMC7455L1",
    deviceManufacturer: "SIERRA WIREL",
    deviceSKU: "SWMC7455L1",
    iccid: "89011202000257274974",

    billingPeriodStart: "10/1/2021",
    billingPeriodEnd: "10/31/2021",
    billingSequenceNumber: "43",

    dataUsage: "52.55 GB",
  },
  6149280381: {
    msisdn: "6149280381",
    imei: "089582131902245428",

    pricePlan: "Custom IoT IA 250MB Pool",
    priceCode: "PDSLH0529",
    priceType: "H",
    priceMRC: "$3.00",
    autoPay: "yes",

    status: "active",

    deviceName: "SIERRA WIREL SWMC7455L1",
    deviceManufacturer: "SIERRA WIREL",
    deviceSKU: "SWMC7455L1",
    iccid: "89011202000261868928",

    billingPeriodStart: "10/1/2021",
    billingPeriodEnd: "10/31/2021",
    billingSequenceNumber: "43",

    dataUsage: "50.56 GB",
  },
  6149460548: {
    msisdn: "6149460548",
    imei: "089582131902593938",

    pricePlan: "Custom IoT IA 250MB Pool",
    priceCode: "PDSLH0529",
    priceType: "H",
    priceMRC: "$3.00",
    autoPay: "yes",

    status: "active",

    deviceName: "SIERRA WIREL SWMC7455L1",
    deviceManufacturer: "SIERRA WIREL",
    deviceSKU: "SWMC7455L1",
    iccid: "89011202000261902495",

    billingPeriodStart: "10/1/2021",
    billingPeriodEnd: "10/31/2021",
    billingSequenceNumber: "43",

    dataUsage: "48.48 GB",
  },
};

type Actions = ActionType<typeof actions>;

const reducer = createReducer<Record<string, Item>, Actions>(
  initialData
).handleAction(actions.updateByID, (state, { payload: { id, update } }) => ({
  ...state,
  [id]: { ...state[id], ...update },
}));

export default reducer;
