import * as React from "react";
import {
  Typography,
  Form,
  Input,
  PhoneInput,
  Row,
  Col,
  Button,
  notification,
  Card,
} from "components";
import styles from "./Profile.module.css";

const Profile: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const onSubmit = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      notification.success({ message: "Account updated successfully." });
    }, 1500);
  };

  return (
    <Card
      className={styles.card}
      title={
        <Typography size="24" bold>
          Account
        </Typography>
      }
    >
      <Form layout="vertical">
        <Row gutter={24}>
          <Col xs={12}>
            <Form.Item label="First Name">
              <Input defaultValue="Brandon" />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label="Last Name">
              <Input defaultValue="Johnson" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={12}>
            <Form.Item label="Phone">
              <PhoneInput
                className={styles.phoneInput}
                disableDropdown
                value="+17148308321"
                countryCode="us"
              />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label="Email">
              <Input defaultValue="brandon@virtuwireless.com" />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end">
          <Button onClick={onSubmit} loading={loading} type="primary">
            Update
          </Button>
        </Row>
      </Form>
    </Card>
  );
};

export default Profile;
