import * as React from "react";
import { Content } from "components";
import { Sidebar, MainInfo } from "./components";
import styles from "./Show.module.css";

const Show: React.FC = () => {
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <Content>
      <Sidebar className={styles.sidebar} loading={loading} />
      <MainInfo loading={loading} />
    </Content>
  );
};

export default Show;
