import * as React from "react";
import { routes } from "configs";
import { qs } from "utils";
import { useHistory, useLocation } from "hooks";
import { Button, Modal, Typography, Space } from "components";
import { SystemFilter, SelectedFilters } from "./components";
import { Types } from "./duck";
import styles from "./Filter.module.css";

const Filter: React.FC = () => {
  const location = useLocation();
  const history = useHistory();

  const {
    over: overMB,
    status,
    columns,
    pageSize,
    sortBy,
  } = qs.parse(location.search.replace("?", "")) as any;

  const [visible, setVisible] = React.useState(false);
  const [filter, setFilter] = React.useState<Types.FilterState | null>(
    (overMB
      ? { data: { over: { categoryIds: ["1"], amountMB: overMB } } }
      : null) as any
  );

  const onSubmit = () => {
    const over = filter?.data?.over.amountMB;

    const newParams = {
      ...(over ? { over } : {}),
      ...(status ? { status } : {}),
      ...(columns ? { columns } : {}),
      ...(pageSize ? { pageSize } : {}),
      ...(sortBy ? { sortBy } : {}),
    };
    const newSearch = qs.stringify(newParams);
    history.push(`${routes.manage}?${newSearch}`);
    setVisible(false);
  };

  return (
    <>
      <Button type="primary" onClick={() => setVisible(true)}>
        Filter Subscribers
      </Button>
      <Modal
        footer={null}
        visible={visible}
        onCancel={() => setVisible(false)}
        width="1100px"
        destroyOnClose
      >
        <Typography size="24" style={{ marginBottom: 24, display: "block" }}>
          Filters
        </Typography>

        <div className={styles.filterWrapper}>
          <div>
            <Typography size="16" style={{ display: "block", marginBottom: 4 }}>
              Filter for BAN
            </Typography>
            <SystemFilter
              dataFilter={filter?.data || null}
              onDataFilterSave={(newDataFilter) => {
                // eslint-disable-next-line
                // @ts-ignore
                setFilter((prev) => ({
                  ...prev,
                  data: {
                    ...prev?.data,
                    ...newDataFilter,
                  },
                }));
              }}
            />
          </div>
          <div>
            <SelectedFilters filter={filter} setFilter={setFilter} />
          </div>
        </div>

        <Space direction="horizontal" size={16} className={styles.controls}>
          <Button type="primary" ghost onClick={() => setFilter(null)}>
            Clear All
          </Button>
          <Button type="primary" onClick={onSubmit}>
            Create Filter
          </Button>
        </Space>
      </Modal>
    </>
  );
};

export default Filter;
