export default {
  manage: "/manage",
  subscriber: (id: string) => `/manage/${id}`,

  activate: "/activate",
  activateSingle: "/activate/single",
  activateBulk: "/activate/bulk",

  orders: "/orders",

  account: "/account",
};
