import * as React from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Input } from "components";

const Search: React.FC = () => {
  return (
    <Input
      style={{ minWidth: 250 }}
      suffix={<SearchOutlined />}
      placeholder="Search"
    />
  );
};

export default Search;
