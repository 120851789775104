/* eslint-disable */
import React from "react";
import classNames from "classnames";
import {
  startsWith,
  memoize,
  findIndex,
  head,
  tail,
  reduce,
  map,
  find,
  trim,
} from "lodash-es";
import { allCountries } from "./CountryData";
import st from "./react-tel-input.css";

class PhoneInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initState(props);
  }

  initState = (props) => {
    let formattedNumber = props.value || "";
    if (formattedNumber[0] === "+") {
      formattedNumber = formattedNumber.slice(1);
    }
    const guessedCountry = this.guessCountry(formattedNumber, allCountries);
    let countries = this.getCountries(props.onlyCountries, allCountries);
    if (props.disableAreaCodes) {
      countries = this.deleteAreaCodes(countries);
    }
    let defaultIndex = -1;

    if (guessedCountry !== null) {
      defaultIndex = countries.findIndex(
        (el) => el.iso2 === guessedCountry.iso2
      );
    }

    if (props.defaultCountry !== null && defaultIndex === -1) {
      defaultIndex = countries.findIndex(
        (el) => el.iso2 === props.defaultCountry
      );
    }

    if (props.countryCode) {
      defaultIndex = countries.findIndex((el) => el.iso2 === props.countryCode);
    }

    if (defaultIndex === -1) {
      defaultIndex = 0;
    }

    if (startsWith(formattedNumber, countries[defaultIndex].dialCode)) {
      formattedNumber = formattedNumber.slice(
        countries[defaultIndex].dialCode.length
      );
      formattedNumber = this.formatNumber(
        formattedNumber,
        countries[defaultIndex].format
      );
    }

    return {
      formattedNumber,
      countries,
      selectedCountry:
        defaultIndex === -1 ? countries[0] : countries[defaultIndex],
      highlightCountryIndex: defaultIndex === -1 ? 0 : defaultIndex,
      showDropdown: false,
      freezeSelection: false,
    };
  };

  guessCountry = memoize((inputNumber, onlyCountries, defaultCountry = "") => {
    const secondBestGuess = find(onlyCountries, { iso2: defaultCountry }) || {};
    if (trim(inputNumber) === "") {
      return secondBestGuess;
    }

    const bestGuess = reduce(
      onlyCountries,
      (selectedCountry, country) => {
        if (startsWith(inputNumber, country.dialCode)) {
          if (country.dialCode.length > selectedCountry.dialCode.length) {
            return country;
          }
          if (
            country.dialCode.length === selectedCountry.dialCode.length &&
            country.priority < selectedCountry.priority
          ) {
            return country;
          }
        }
        return selectedCountry;
      },
      { dialCode: "", priority: 10001 }
    );

    if (!bestGuess.name) {
      return secondBestGuess;
    }
    return bestGuess;
  });

  componentDidMount() {
    if (document.addEventListener) {
      document.addEventListener("mousedown", this.handleClickOutside);
      document.addEventListener("keydown", this.handleKeydown);
    }
  }

  componentWillUnmount() {
    if (document.removeEventListener) {
      document.removeEventListener("mousedown", this.handleClickOutside);
      document.removeEventListener("keydown", this.handleKeydown);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.value !== this.props.value ||
      prevProps.countryCode !== this.props.countryCode
    ) {
      const state = this.initState(this.props);
      this.setState(state);
    }

    if (prevProps.countryCode !== this.props.countryCode) {
      const { formattedNumber } = this.state;

      const newCountry = allCountries.find(
        (country) => country.iso2 === this.props.countryCode
      );

      if (!newCountry) {
        return;
      }

      const newNumber = this.formatNumber(
        formattedNumber.replace(/[^0-9]/g, ""),
        newCountry.format
      );
      this.setState({ formattedNumber: newNumber }, () => {
        if (this.props.onChange) {
          this.props.onChange(
            `+${this.state.selectedCountry.dialCode}${formattedNumber}`,
            this.getCountryData()
          );
        }
      });
    }
  }

  handleClickOutside = (e) => {
    if (this.dropdownRef && !this.dropdownContainerRef.contains(e.target)) {
      if (this.state.showDropdown) {
        this.setState({ showDropdown: false });
      }
    }
  };

  getHighlightCountryIndex = (direction) => {
    // had to write own function because underscore does not have findIndex. lodash has it
    const highlightCountryIndex = this.state.highlightCountryIndex + direction;

    if (
      highlightCountryIndex < 0 ||
      highlightCountryIndex >= this.state.countries.length
    ) {
      return highlightCountryIndex - direction;
    }

    return highlightCountryIndex;
  };

  handleKeydown = (e) => {
    const { keys } = this.props;
    if (!this.state.showDropdown || this.props.disabled) {
      return;
    }
    const {
      target: { id },
    } = e;
    if (id === "search-box") {
      return;
    } // don't process events coming from the search field

    // ie hack
    if (e.preventDefault) {
      e.preventDefault();
    } else {
      e.returnValue = false;
    }

    const moveHighlight = (direction) => {
      this.setState(
        {
          highlightCountryIndex: this.getHighlightCountryIndex(direction),
        },
        () => {
          this.scrollTo(
            this.getElement(this.state.highlightCountryIndex),
            true
          );
        }
      );
    };

    switch (e.which) {
      case keys.DOWN:
        moveHighlight(1);
        break;
      case keys.UP:
        moveHighlight(-1);
        break;
      case keys.ENTER:
        this.handleFlagItemClick(
          this.state.countries[this.state.highlightCountryIndex],
          e
        );
        break;
      case keys.ESC:
        this.setState(
          {
            showDropdown: false,
          },
          this.cursorToEnd
        );
        break;
      default:
        if (
          (e.which >= keys.A && e.which <= keys.Z) ||
          e.which === keys.SPACE
        ) {
          this.setState(
            (state) => ({
              queryString: state.queryString + String.fromCharCode(e.which),
            }),
            this.state.debouncedQueryStingSearcher
          );
        }
    }
  };

  deleteAreaCodes = (filteredCountries) =>
    filteredCountries.filter((country) => country.isAreaCode !== true);

  getCountries = (countriesArray = [], filteredCountries) => {
    if (countriesArray.length === 0) {
      return filteredCountries;
    }
    return filteredCountries.filter((country) =>
      countriesArray.some((element) => element === country.iso2)
    );
  };

  getElement = (index) => this[`flag_no_${index}`];

  getCountryDropdownList = () => {
    const {
      state: { countries, highlightCountryIndex, showDropdown },
    } = this;

    const countryDropdownList = map(countries, (country, index) => {
      const itemClasses = classNames({
        [st.country]: true,
        [st.highlight]: highlightCountryIndex === index,
      });

      const inputFlagClasses = classNames([st.flag], st[country.iso2]);

      return (
        <li
          ref={(el) => {
            this[`flag_no_${index}`] = el;
          }}
          key={`flag_no_${index}`}
          data-flag-key={`flag_no_${index}`}
          className={itemClasses}
          data-dial-code="1"
          data-country-code={country.iso2}
          onClick={() => this.handleFlagItemClick(country)}
        >
          <div className={inputFlagClasses} />
          <span className={st["country-name"]}>{country.name}</span>
          <span className={st["dial-code"]}>{`+${country.dialCode}`}</span>
        </li>
      );
    });

    const dropDownClasses = classNames({
      [this.props.dropdownClass]: true,
      [st["country-list"]]: true,
      [st.hide]: !showDropdown,
    });

    return (
      <ul
        ref={(el) => {
          this.dropdownRef = el;
        }}
        className={dropDownClasses}
        style={this.props.dropdownStyle}
      >
        {countryDropdownList.length > 0 ? (
          countryDropdownList
        ) : (
          <li className={st["no-entries-message"]}>
            <span>No entries to show.</span>
          </li>
        )}
      </ul>
    );
  };

  // View methods
  scrollTo = (country, middle) => {
    if (!country) {
      return;
    }

    const container = this.dropdownRef;

    if (!container || !document.body) {
      return;
    }

    const containerHeight = container.offsetHeight;
    const containerOffset = container.getBoundingClientRect();
    const containerTop = containerOffset.top + document.body.scrollTop;
    const containerBottom = containerTop + containerHeight;

    const element = country;
    const elementOffset = element.getBoundingClientRect();

    const elementHeight = element.offsetHeight;
    const elementTop = elementOffset.top + document.body.scrollTop;
    const elementBottom = elementTop + elementHeight;

    let newScrollTop = elementTop - containerTop + container.scrollTop;
    const middleOffset = containerHeight / 2 - elementHeight / 2;

    if (elementTop < containerTop) {
      // scroll up
      if (middle) {
        newScrollTop -= middleOffset;
      }
      container.scrollTop = newScrollTop;
    } else if (elementBottom > containerBottom) {
      // scroll down
      if (middle) {
        newScrollTop += middleOffset;
      }
      const heightDifference = containerHeight - elementHeight;
      container.scrollTop = newScrollTop - heightDifference;
    }
  };

  handleFlagDropdownClick = () => {
    if (!this.state.showDropdown && this.props.disabled) {
      return;
    }
    this.setState(
      (state) => ({
        showDropdown: !state.showDropdown,
      }),
      () => {
        if (this.state.showDropdown) {
          this.scrollTo(this.getElement(this.state.highlightCountryIndex));
        }
      }
    );
  };

  // Put the cursor to the end of the input (usually after a focus event)
  cursorToEnd = () => {
    const input = this.numberInputRef;
    input.focus();
    if (this.props.isModernBrowser) {
      const len = input.value.length;
      input.setSelectionRange(len, len);
    }
  };

  handleFlagItemClick = (country) => {
    const { countries: allCountries } = this.state;
    let { formattedNumber } = this.state;
    const nextSelectedCountryIndex = findIndex(allCountries, country);
    formattedNumber = this.formatNumber(
      formattedNumber.replace(/[^0-9]/g, ""),
      allCountries[nextSelectedCountryIndex].format
    );

    this.setState(
      {
        showDropdown: false,
        selectedCountry: allCountries[nextSelectedCountryIndex],
        freezeSelection: true,
        highlightCountryIndex: nextSelectedCountryIndex,
        formattedNumber,
      },
      () => {
        this.cursorToEnd();
        if (this.props.onChange) {
          this.props.onChange(
            `+${this.state.selectedCountry.dialCode}${formattedNumber}`,
            this.getCountryData()
          );
        }
      }
    );
  };

  getCountryData = () => {
    const country = this.state.selectedCountry || {};
    return {
      name: country.name || "",
      dialCode: country.dialCode || "",
      countryCode: country.iso2 || "",
      format: country.format || "",
      phoneLength: country.format
        ? country.format.split("").filter((s) => s === ".").length +
          (country.dialCode.length || 1)
        : 10,
    };
  };

  formatNumber = (text, patternArg) => {
    const { enableLongNumbers } = this.props;
    let pattern;

    if (patternArg) {
      pattern = patternArg.split(" ");
      pattern.shift();
      pattern = pattern.join(" ");
    } else {
      return text.replace(/[^0-9]/g, "");
    }

    if (!text || text.length === 0) {
      return "";
    }

    const formattedObject = reduce(
      pattern,
      (acc, character) => {
        if (acc.remainingText.length === 0) {
          return acc;
        }

        if (character !== ".") {
          return {
            formattedText: acc.formattedText + character,
            remainingText: acc.remainingText,
          };
        }

        return {
          formattedText: acc.formattedText + head(acc.remainingText),
          remainingText: tail(acc.remainingText),
        };
      },
      {
        formattedText: "",
        remainingText: text.split(""),
      }
    );

    let formattedNumber;
    if (enableLongNumbers) {
      formattedNumber =
        formattedObject.formattedText + formattedObject.remainingText.join("");
    } else {
      formattedNumber = formattedObject.formattedText;
    }

    // Always close brackets
    if (formattedNumber.includes("(") && !formattedNumber.includes(")")) {
      formattedNumber += ")";
    }
    return formattedNumber;
  };

  handleInput = (e) => {
    let formattedNumber = "";
    const { selectedCountry: newSelectedCountry, freezeSelection } = this.state;

    // Does not exceed 15 digit phone number limit
    if (
      e.target.value.replace(/\D/g, "").length +
        this.state.selectedCountry.dialCode.length >
      15
    ) {
      return;
    }

    // if the input is the same as before, must be some special key like enter etc.
    if (e.target.value === this.state.formattedNumber) {
      return;
    }

    // ie hack
    if (e.preventDefault) {
      e.preventDefault();
    } else {
      e.returnValue = false;
    }

    if (e.target.value.length > 0) {
      const inputNumber = e.target.value.replace(/\D/g, "");
      formattedNumber = this.formatNumber(
        inputNumber,
        newSelectedCountry.format
      );
    }

    let caretPosition = e.target.selectionStart;
    const oldFormattedText = this.state.formattedNumber;
    const diff = formattedNumber.length - oldFormattedText.length;

    this.setState(
      (state) => ({
        formattedNumber,
        freezeSelection,
        selectedCountry: newSelectedCountry.dialCode
          ? newSelectedCountry
          : state.selectedCountry,
      }),
      () => {
        if (this.props.isModernBrowser) {
          if (diff > 0) {
            caretPosition -= diff;
          }

          const lastChar = formattedNumber.charAt(formattedNumber.length - 1);

          if (lastChar === ")") {
            this.numberInputRef.setSelectionRange(
              formattedNumber.length - 1,
              formattedNumber.length - 1
            );
          } else if (
            caretPosition > 0 &&
            oldFormattedText.length >= formattedNumber.length
          ) {
            this.numberInputRef.setSelectionRange(caretPosition, caretPosition);
          }
        }

        if (this.props.onChange) {
          this.props.onChange(
            `+${this.state.selectedCountry.dialCode}${formattedNumber}`,
            this.getCountryData()
          );
        }
      }
    );
  };

  handlePaste = (e) => {
    e.preventDefault();
    const enteredValue = e.target.value;

    const {
      selectedCountry: { dialCode, format },
    } = this.state;
    const pastedValue = window.clipboardData
      ? window.clipboardData.getData("Text") // IE
      : e.clipboardData.getData("text/plain");

    const rawPastedValue = `${enteredValue}${pastedValue}`.replace(
      /[^0-9]/gim,
      ""
    );

    const phone = startsWith(rawPastedValue, dialCode)
      ? rawPastedValue.slice(dialCode.length)
      : rawPastedValue;

    const formattedNumber = this.formatNumber(phone, format);
    this.setState({ formattedNumber });

    if (this.props.onChange) {
      this.props.onChange(
        `+${dialCode}${formattedNumber}`,
        this.getCountryData()
      );
    }
  };

  render() {
    const { selectedCountry, showDropdown, formattedNumber, countries } =
      this.state;
    const isActive = countries.length !== 1;
    const flagViewClasses = classNames({
      [this.props.buttonClass]: true,
      [st["flag-dropdown"]]: true,
      [st["open-dropdown"]]: showDropdown,
    });
    const arrowClasses = classNames({
      [st.arrow]: true,
      [st.up]: showDropdown,
    });
    const { prefix } = this.props;

    const inputProps = {
      name: this.props.name,
      placeholder: this.props.placeholder,
      className: classNames(st.input, {
        [this.props.inputClass]: true,
      }),
      ref: (el) => {
        this.numberInputRef = el;
      },
      style: this.props.inputStyle,
      onChange: this.handleInput,
      onBlur: this.props.onBlur,
      onPaste: this.handlePaste,
      disabled: this.props.disabled,
      value: formattedNumber,
      type: "tel",
      ...this.props.inputExtraProps,
    };

    return (
      <div
        className={classNames(this.props.containerClass, this.props.className)}
        style={this.props.containerStyle}
      >
        {!!prefix ? (
          <div className={classNames(st.prefixWrapper, "prefix-wrapper")}>
            {prefix}
          </div>
        ) : (
          <div
            onClick={
              this.props.disableDropdown
                ? () => {}
                : this.handleFlagDropdownClick
            }
            className={classNames(st["selected-flag"], "country-dropdown", {
              [st["selected-flag-gray"]]: !isActive,
            })}
            title={
              selectedCountry
                ? `${selectedCountry.name}: + ${selectedCountry.dialCode}`
                : ""
            }
          >
            {isActive && <div className={arrowClasses} />}+
            {selectedCountry.dialCode}
          </div>
        )}
        {!!prefix ? (
          // TODO: Remove data attribute
          <div className={classNames(st.inputWrapper, "input-wrapper")}>
            <span className={classNames(st.selectedDialCode, "dial-code")}>
              +{selectedCountry.dialCode}&nbsp;
            </span>
            <input {...inputProps} />
          </div>
        ) : (
          <input {...inputProps} />
        )}
        <div
          className={flagViewClasses}
          style={this.props.buttonStyle}
          onKeyDown={this.handleKeydown}
          ref={(el) => {
            this.dropdownContainerRef = el;
          }}
        >
          {isActive && showDropdown && this.getCountryDropdownList()}
        </div>
      </div>
    );
  }
}

PhoneInput.defaultProps = {
  countries: [],
  defaultCountry: null,

  value: "",
  placeholder: "(999) 999-999",
  flagsImagePath: "./flags.png",
  disabled: false,
  onBlur: () => {},

  containerStyle: {},
  inputStyle: {},
  buttonStyle: {},
  dropdownStyle: {},

  containerClass: st["react-tel-input"],
  inputClass: "",
  buttonClass: "",
  dropdownClass: "",
  searchClass: "",

  autoFormat: true,
  disableAreaCodes: true,
  disableCountryCode: false,
  disableDropdown: false,
  enableLongNumbers: false,
  countryCodeEditable: true,
  enableSearchField: false,
  name: "",

  regions: "",

  inputExtraProps: {},
  localization: {},

  isModernBrowser: document.createElement
    ? Boolean(document.createElement("input").setSelectionRange)
    : false,

  keys: {
    UP: 38,
    DOWN: 40,
    RIGHT: 39,
    LEFT: 37,
    ENTER: 13,
    ESC: 27,
    PLUS: 43,
    A: 65,
    Z: 90,
    SPACE: 32,
  },
};

export default PhoneInput;
