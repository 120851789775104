import * as React from "react";
import { useParams, useSelector } from "hooks";
import { Modal, Typography, Row, Space, Input, Button } from "components";
import ActionButton from "../action-button";
import Card from "../card";
import styles from "./ChangeDevice.module.css";

const ChangeDevice: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const data = useSelector((state) => state.mock[id]);

  const [visible, setVisible] = React.useState(false);
  const [value, setValue] = React.useState("");

  const [success, setSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (!visible) {
      setValue("");
      setSuccess(false);
      setLoading(false);
    }
  }, [visible]);

  const onSubmit = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSuccess(true);
    }, 1000);
  };

  return (
    <>
      <ActionButton onClick={() => setVisible(true)}>
        Change Device
      </ActionButton>
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        destroyOnClose
        width="700px"
      >
        {success ? (
          <Space
            direction="vertical"
            size={24}
            className={styles.successWrapper}
          >
            <Typography size="24">Device Change Successful!</Typography>
            <Button type="primary" onClick={() => setVisible(false)}>
              Ok
            </Button>
          </Space>
        ) : (
          <>
            <Typography size="24" style={{ marginBottom: 24 }}>
              Change Device
            </Typography>
            <Card>
              <Space direction="vertical" size={8} style={{ width: "100%" }}>
                <Row justify="space-between">
                  <Typography bold>Device Name</Typography>
                  <Typography>{data.deviceName}</Typography>
                </Row>
                <Row justify="space-between">
                  <Typography bold>Device Manufacturer</Typography>
                  <Typography>{data.deviceManufacturer}</Typography>
                </Row>
                <Row justify="space-between">
                  <Typography bold>Device SKU</Typography>
                  <Typography>{data.deviceSKU}</Typography>
                </Row>
                <Row justify="space-between">
                  <Typography bold>IMEI</Typography>
                  <Typography>{data.imei}</Typography>
                </Row>

                <Row justify="space-between">
                  <Typography bold>ICCID</Typography>
                  <Typography>{data.iccid}</Typography>
                </Row>
              </Space>
            </Card>

            <div className={styles.newDevice}>
              <Typography size="20" style={{ marginBottom: 8, marginTop: 24 }}>
                New Device
              </Typography>
              <Typography style={{ marginBottom: 8 }}>
                Enter in the IMEI or MEID decimal value for the new device
              </Typography>

              <div
                style={{ display: "flex", alignItems: "center", columnGap: 16 }}
              >
                <Typography bold>IMEI/MEID</Typography>
                <Input
                  placeholder="Enter new device..."
                  style={{ display: "inline" }}
                  value={value}
                  onChange={(event) => setValue(event.target.value)}
                />
              </div>
            </div>
            <Space direction="horizontal" size={16}>
              <Button type="primary" ghost onClick={() => setVisible(false)}>
                Cancel
              </Button>
              <Button
                loading={loading}
                disabled={!value}
                type="primary"
                onClick={onSubmit}
              >
                Change Device
              </Button>
            </Space>
          </>
        )}
      </Modal>
    </>
  );
};

export default ChangeDevice;
