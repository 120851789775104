import * as React from "react";
import { Space, Typography, Table, Button } from "components";
import styles from "./Plans.module.css";

const data = [
  {
    pricePlan: "Custom IoT IA 250MB Pool",
    priceCode: "PDSLH0529",
    usage: "250MB Pool",
  },
];

const Plans: React.FC = () => {
  const [selectedIds, setSelectedIds] = React.useState<string[]>([]);

  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      <Space direction="vertical" size={8}>
        <Typography size="24">Service Plans</Typography>
      </Space>
      <Table
        dataSource={loading ? [] : data}
        loading={loading}
        pagination={false}
        className={styles.table}
        scroll={{ y: 300 }}
        rowKey="id"
        rowSelection={{
          type: "radio",
          selectedRowKeys: selectedIds,
          onChange: setSelectedIds as any,
        }}
      >
        <Table.Column
          title="Plan"
          dataIndex="pricePlan"
          render={(name: string) => (
            <Button type="link" className={styles.link}>
              {name}
            </Button>
          )}
        />
        <Table.Column title="Usage" dataIndex="usage" />
        <Table.Column title="Code" dataIndex="priceCode" />
      </Table>
    </>
  );
};

export default Plans;
