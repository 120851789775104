import * as React from "react";
import { Button } from "components";
import styles from "./Action.module.css";

interface ActionButtonProps {
  children: React.ReactNode;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const ActionButton: React.FC<ActionButtonProps> = ({ children, onClick }) => (
  <Button type="primary" ghost onClick={onClick} className={styles.wrapper}>
    {children}
  </Button>
);

export default ActionButton;
