import * as React from "react";
import { updateByID } from "configs/mock-reducer/actions";
import { useParams, useSelector, useDispatch } from "hooks";
import { Modal, Button, Space, Typography, Row, Input } from "components";
import ActionButton from "../action-button";
import Card from "../card";
import styles from "./SwapSIM.module.css";

const SwapSIM: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const data = useSelector((state) => state.mock[id]);

  const dispatch = useDispatch();

  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState("");
  const [success, setSuccess] = React.useState(false);

  React.useEffect(() => {
    if (!visible) {
      setValue("");
      setLoading(false);
      setSuccess(false);
    }
  }, [visible]);

  const onSubmit = () => {
    setLoading(true);
    setTimeout(() => {
      dispatch(updateByID({ id, update: { iccid: value } }));
      setLoading(false);
      setSuccess(true);
    }, 1000);
  };

  return (
    <>
      <ActionButton onClick={() => setVisible(true)}>SIM Swap</ActionButton>
      <Modal
        footer={null}
        onCancel={() => setVisible(false)}
        visible={visible}
        destroyOnClose
        width="700px"
      >
        {success ? (
          <Space
            direction="vertical"
            size={24}
            className={styles.successWrapper}
          >
            <Typography size="24">SIM ICCID Change Successful!</Typography>
            <Button
              type="primary"
              onClick={() => {
                setVisible(false);
              }}
            >
              Ok
            </Button>
          </Space>
        ) : (
          <>
            <Typography style={{ marginBottom: 24 }} size="24">
              SIM Swap
            </Typography>
            <Card>
              <Row justify="space-between">
                <Typography bold>Current SIM ICCID:</Typography>
                <Typography>{data.iccid}</Typography>
              </Row>
            </Card>

            <div className={styles.row}>
              <Typography bold style={{ whiteSpace: "nowrap" }}>
                New SIM ICCID
              </Typography>
              <Input
                placeholder="Enter new ICCID..."
                value={value}
                onChange={(event) => setValue(event.target.value)}
              />
            </div>

            <Space direction="horizontal" size={16}>
              <Button type="primary" ghost onClick={() => setVisible(false)}>
                Cancel
              </Button>
              <Button
                loading={loading}
                disabled={!value}
                type="primary"
                onClick={onSubmit}
              >
                Change
              </Button>
            </Space>
          </>
        )}
      </Modal>
    </>
  );
};

export default SwapSIM;
