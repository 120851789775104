import * as React from "react";
import { useParams, useSelector, useDispatch } from "hooks";
import { updateByID } from "configs/mock-reducer/actions";
import { Modal, Button, Typography, Space, Row } from "components";
import ActionButton from "../action-button";
import Card from "../card";
import { AvailablePlans } from "./components";
import styles from "./ChangePlan.module.css";

const ChangePlan: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const data = useSelector((state) => state.mock[id]);
  const dispatch = useDispatch();

  const [visible, setVisible] = React.useState(false);

  const [plan, setPlan] = React.useState<{
    pricePlan: string;
    priceCode: string;
    priceMRC: string;
  } | null>(null);

  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  React.useEffect(() => {
    if (!visible) {
      setPlan(null);
      setSuccess(false);
      setLoading(false);
    }
  }, [visible]);

  const onSubmit = () => {
    if (!plan) {
      return;
    }
    setLoading(true);

    setTimeout(() => {
      dispatch(
        updateByID({
          id,
          update: {
            priceMRC: plan.priceMRC,
            priceCode: plan.priceCode,
            pricePlan: plan.pricePlan,
          },
        })
      );
      setLoading(false);
      setSuccess(true);
    }, 1000);
  };

  return (
    <>
      <ActionButton onClick={() => setVisible(true)}>Change Plan</ActionButton>
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        destroyOnClose
        width="700px"
      >
        {success ? (
          <Space
            direction="vertical"
            size={24}
            className={styles.successWrapper}
          >
            <Typography size="24">Plan Change Successful!</Typography>
            <Button
              type="primary"
              onClick={() => {
                setVisible(false);
              }}
            >
              Ok
            </Button>
          </Space>
        ) : (
          <div className={styles.wrapper}>
            <Typography size="24" tag="div" style={{ marginBottom: 24 }}>
              Change Device Rate Plan
            </Typography>

            <Card>
              <Space direction="vertical" size={8} style={{ width: "100%" }}>
                <Row justify="space-between">
                  <Typography bold>Current Plan:</Typography>
                  <Typography>{data.pricePlan}</Typography>
                </Row>

                <Row justify="space-between">
                  <Typography bold>Current Price Code:</Typography>
                  <Typography>{data.priceCode}</Typography>
                </Row>

                <Row justify="space-between">
                  <Typography bold>Current MRC:</Typography>
                  <Typography>{data.priceMRC}</Typography>
                </Row>
              </Space>
            </Card>

            <Typography
              size="20"
              tag="div"
              style={{ marginTop: 24, marginBottom: 16 }}
            >
              Available Plan Options
            </Typography>
            <AvailablePlans
              onSelect={setPlan}
              selectedId={plan?.pricePlan}
              // removedName={data.pricePlan}
            />

            <Space direction="horizontal" size={16} style={{ marginTop: 24 }}>
              <Button type="primary" ghost onClick={() => setVisible(false)}>
                Cancel
              </Button>
              <Button
                loading={loading}
                disabled={!plan}
                type="primary"
                onClick={onSubmit}
              >
                Change Plan
              </Button>
            </Space>
          </div>
        )}
      </Modal>
    </>
  );
};

export default ChangePlan;
