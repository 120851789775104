import { PhoneValue } from "types";

const validatePhone = (phone: PhoneValue) => {
  const { value, country } = phone;
  const length = country?.phoneLength || 1;

  const formatPhone = value.replace(/\D/g, "");
  if (!formatPhone) {
    return "Phone number is required.";
  }
  if (formatPhone.length < length) {
    return `Phone number must contain ${length} characters.`;
  }
};

export default validatePhone;
