import * as React from "react";
import { routes } from "configs";
import { Switch, Route, Redirect } from "react-router-dom";
import { RouteComponentProps } from "types";
import { List, Show } from "./components";

const Manage: React.FC<RouteComponentProps> = () => (
  <Switch>
    <Route exact path={routes.subscriber(":id")} component={Show} />
    <Route exact path={routes.manage} component={List} />
    <Redirect to={routes.manage} />
  </Switch>
);

export default Manage;
