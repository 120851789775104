import * as React from "react";
import { routes } from "configs";
import { useHistory, useLocation } from "hooks";
import { qs } from "utils";
import {
  Select,
  Button,
  Modal,
  InputNumber,
  Form,
  Typography,
} from "components";
import { constants } from "./duck";
import { ColumnPicker } from "./components";

const ConfigureLayout: React.FC = () => {
  const [visible, setVisible] = React.useState(false);
  const history = useHistory();
  const location = useLocation();

  const { pageSize, columns, over } = qs.parse(
    location.search.replace("?", "")
  ) as any;

  const onSubmit = (values: {
    columnIds: string[];
    pageSize: number;
    sort: string;
  }) => {
    const query = qs.stringify({
      columns: values.columnIds,
      pageSize: values.pageSize || 20,
      sortBy: values.sort,
      ...(over ? { over } : {}),
    });
    history.push(`${routes.manage}?${query}`);
    setVisible(false);
  };

  return (
    <>
      <Button type="primary" onClick={() => setVisible(true)}>
        Layout
      </Button>
      <Modal footer={null} visible={visible} onCancel={() => setVisible(false)}>
        <Typography size="24" style={{ marginBottom: 16 }}>
          Layout
        </Typography>
        <Form
          layout="vertical"
          initialValues={{
            sort: "msisdn",
            pageSize: +(pageSize || 20),
            columnIds: columns || constants.DEFAULT_COLUMN_IDS,
          }}
          onFinish={onSubmit}
        >
          <Form.Item name="columnIds" label="Column Selector ">
            {/*
            // eslint-disable-next-line
            // @ts-ignore */}
            <ColumnPicker />
          </Form.Item>
          <Form.Item
            name="pageSize"
            label="Enter number of rows to show per page"
            help="If left blank default will be 20"
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item name="sort" label="Choose column to sort data by">
            <Select>
              {constants.COLUMN_OPTIONS.map((opt) => (
                <Select.Option key={opt.id} value={opt.id}>
                  {opt.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Button type="primary" htmlType="submit">
            Save Layout Rule
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default ConfigureLayout;
