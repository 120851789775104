import * as React from "react";
import { classNames } from "utils";
import { Button, Space } from "components";
import { constants } from "../../duck";
import styles from "./ColumnPicker.module.css";

interface ColumnPickerProps {
  value: string[];
  onChange(value: string[]): void;
}

const ColumnPicker: React.FC<ColumnPickerProps> = ({ value, onChange }) => {
  return (
    <Space className={styles.wrapper} direction="vertical" size={6}>
      {constants.COLUMN_OPTIONS.map((opt) => (
        <Button
          className={classNames(styles.button, {
            [styles.bold]: value.includes(opt.id),
          })}
          onClick={() => {
            const selected = value.includes(opt.id);
            onChange(
              selected
                ? value.filter((id) => id !== opt.id)
                : [...value, opt.id]
            );
          }}
          key={opt.id}
        >
          {opt.name}
        </Button>
      ))}
    </Space>
  );
};

export default ColumnPicker;
