import * as React from "react";
import { Space } from "components";
import { TopBar, Table } from "./components";

const Subscribers: React.FC = () => {
  return (
    <Space direction="vertical" size={24}>
      <TopBar />
      <Table />
    </Space>
  );
};

export default Subscribers;
