import { createStore, combineReducers } from "redux";
import { StateType } from "typesafe-actions";
import mockReducer from "./mock-reducer";

const rootReducer = combineReducers({
  mock: mockReducer,
});

export type RootState = StateType<typeof rootReducer>;

const store = createStore(rootReducer);

export default store;
