import * as React from "react";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { routes } from "configs";
import { qs } from "utils";
import { useLocation, useSelector } from "hooks";
import { Table, Space, Typography, Link } from "components";
import { constants } from "../top-bar/components/configure-layout/duck";
import styles from "./Table.module.css";

const SubscribersTable: React.FC = () => {
  const [loading, setLoading] = React.useState(true);

  const location = useLocation();

  React.useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [location.search]);

  const {
    status,
    columns = constants.DEFAULT_COLUMN_IDS,
    pageSize = 20,
    sortBy,
    over,
  } = qs.parse(location.search.replace("?", "")) as any;

  const list = useSelector((state) => Object.values(state.mock))
    .sort((a: any, b: any) =>
      a[sortBy || "msisdn"] < b[sortBy || "msisdn"] ? 1 : -1
    )
    .filter((item) => (status ? item.status === status : true))
    .filter((item) =>
      over !== undefined
        ? +item.dataUsage.replace(" GB", "") * 1024 > +over
        : true
    );

  return (
    <>
      {!loading && (
        <Space direction="horizontal" style={{ marginBottom: 8 }}>
          <Typography size="16" bold>
            {list.length}
          </Typography>
          <Typography size="16">Total Subscribers</Typography>
        </Space>
      )}
      <Table
        scroll={{ x: "auto" }}
        rowKey="id"
        dataSource={loading ? [] : list}
        loading={loading}
        className={styles.table}
        pagination={{ showSizeChanger: false, pageSize: +pageSize }}
      >
        {columns.includes("msisdn") && (
          <Table.Column
            title="MDN"
            fixed="left"
            dataIndex="msisdn"
            render={(value: string) => (
              <Link
                to={routes.subscriber(value)}
                className={styles.link}
                type="link"
              >
                {value}
              </Link>
            )}
          />
        )}
        {columns.includes("imei") && (
          <Table.Column title="IMEI" dataIndex="imei" />
        )}
        {columns.includes("iccid") && (
          <Table.Column title="ICCID" dataIndex="iccid" />
        )}
        {columns.includes("deviceName") && (
          <Table.Column title="Device" dataIndex="deviceName" />
        )}
        {columns.includes("pricePlan") && (
          <Table.Column title="Plan" dataIndex="pricePlan" />
        )}
        {columns.includes("status") && (
          <Table.Column
            title="Status"
            dataIndex="status"
            render={(st: string) => {
              return st === "active" ? (
                <>
                  <span className={styles.active}>
                    <CheckCircleFilled />
                  </span>{" "}
                  Active
                </>
              ) : (
                <>
                  <span className={styles.inactive}>
                    <CloseCircleFilled />
                  </span>{" "}
                  Suspended
                </>
              );
            }}
          />
        )}
        {columns.includes("dataUsed") && (
          <Table.Column title="3G/LTE Data" dataIndex="dataUsage" />
        )}
        {columns.includes("voiceMinutes") && (
          <Table.Column title="Voice Minutes" dataIndex="voiceMinutes" />
        )}
        {columns.includes("billingPeriodStart") && (
          <Table.Column
            title="Contract Start Date"
            dataIndex="billingPeriodStart"
          />
        )}
        {columns.includes("billingPeriodEnd") && (
          <Table.Column
            title="Contract End Date"
            dataIndex="billingPeriodEnd"
          />
        )}
      </Table>
    </>
  );
};

export default SubscribersTable;
