import * as React from "react";
import { Menu, Modal } from "components";
import { SelectType, Upload } from "./components";

const Bulk: React.FC = (props) => {
  const [visible, setVisible] = React.useState(false);

  const [type, setType] = React.useState<string | null>("");

  React.useEffect(() => {
    if (!visible) {
      setType(null);
    }
  }, [visible]);

  return (
    <>
      <Menu.Item {...props} onClick={() => setVisible(true)}>
        Bulk
      </Menu.Item>
      <Modal
        footer={null}
        visible={visible}
        onCancel={() => setVisible(false)}
        width="600px"
      >
        {type === null ? (
          <SelectType onSelect={setType} />
        ) : (
          <Upload
            type={type}
            onCancel={() => setType(null)}
            onSuccess={() => setVisible(false)}
          />
        )}
      </Modal>
    </>
  );
};

export default Bulk;
