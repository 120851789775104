import * as React from "react";
import classNames from "classnames";
import styles from "./Typography.module.css";

export interface TypographyProps extends React.HTMLAttributes<HTMLSpanElement> {
  size?: "12" | "14" | "16" | "20" | "22" | "24" | "40";
  tag?: string;
  color?: "black" | "pink" | "white" | "red";
  className?: string;
  bold?: boolean;
  children?: React.ReactNode;
}

const Typography: React.FC<TypographyProps> = ({
  tag = "span",
  className = "",
  size = "14",
  bold = false,
  color = "black",
  children,
  ...rest
}) =>
  React.createElement(
    tag,
    {
      className: classNames(
        styles.wrapper,
        styles[`size${size}`],
        styles[color],
        className,
        {
          [styles.bold]: bold,
        }
      ),
      ...rest,
    },
    children
  );

export default Typography;
