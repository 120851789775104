import * as React from "react";

const PhoneIcon: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.347 17.6136L19.9977 14.2643C18.8015 13.0681 16.768 13.5467 16.2896 15.1016C15.9307 16.1782 14.7346 16.7763 13.658 16.537C11.2657 15.9389 8.03602 12.8289 7.43794 10.317C7.07908 9.24036 7.79679 8.04419 8.87334 7.68538C10.4284 7.20691 10.9068 5.17343 9.71066 3.97726L6.36138 0.627989C5.40445 -0.209329 3.96905 -0.209329 3.13173 0.627989L0.859009 2.90071C-1.41371 5.29305 1.09824 11.6327 6.72023 17.2547C12.3422 22.8767 18.6819 25.5083 21.0743 23.116L23.347 20.8432C24.1843 19.8863 24.1843 18.4509 23.347 17.6136Z"
      fill="white"
    />
  </svg>
);
export default PhoneIcon;
