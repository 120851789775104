import * as React from "react";
import { useParams, useSelector, useDispatch } from "hooks";
import { updateByID } from "configs/mock-reducer/actions";
import { Modal, Typography, Space, Button } from "components";
import ActionButton from "../action-button";
import styles from "./ChangeStatus.module.css";

const ChangeStatus: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const data = useSelector((state) => state.mock[id]);
  const dispatch = useDispatch();

  const [visible, setVisible] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const isActive = data.status === "active";

  const onChangeStatus = () => {
    setLoading(true);
    setTimeout(() => {
      dispatch(
        updateByID({
          id,
          update: { status: isActive ? "suspended" : "active" },
        })
      );
      setLoading(false);
      setSuccess(true);
    }, 1000);
  };

  return (
    <>
      <ActionButton onClick={() => setVisible(true)}>
        {isActive ? "Suspend" : "Enable"}
      </ActionButton>
      <Modal
        width="600px"
        footer={null}
        visible={visible}
        onCancel={() => {
          setVisible(false);
          setSuccess(false);
        }}
      >
        {success ? (
          <Space direction="vertical" size={24} className={styles.success}>
            <Typography size="24">
              {!isActive ? "Suspension Successful!" : "Activation Successful!"}
            </Typography>
            <Button
              type="primary"
              onClick={() => {
                setVisible(false);
                setSuccess(false);
              }}
            >
              Ok
            </Button>
          </Space>
        ) : (
          <Space direction="vertical" size={24}>
            <Typography size="24">
              {isActive ? "Suspend device" : "Activate Device"}
            </Typography>

            {isActive ? (
              <Typography>
                Are you sure you want to suspend this device? <br />
                After suspending the device, it will not work on the T-mobile
                network. <br />
                Device operation can be restored later by clicking Enable.
              </Typography>
            ) : (
              <Typography>
                Are you sure you want to enable this device?
              </Typography>
            )}

            <Space direction="horizontal" size={16}>
              <Button type="primary" ghost onClick={() => setVisible(false)}>
                Cancel
              </Button>
              <Button loading={loading} type="primary" onClick={onChangeStatus}>
                {data.status === "active" ? "Suspend" : "Enable"}
              </Button>
            </Space>
          </Space>
        )}
      </Modal>
    </>
  );
};

export default ChangeStatus;
