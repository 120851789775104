import * as React from "react";
import { Typography, Table } from "components";
import styles from "./AdditionalServices.module.css";

const AdditionalServices: React.FC = () => {
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  return (
    <>
      <Typography size="24">Additional Services and Features</Typography>
      <Table
        dataSource={[]}
        loading={loading}
        pagination={false}
        className={styles.table}
      >
        <Table.Column title="Plan" dataIndex="name" />
        <Table.Column title="Per Month" dataIndex="perMonth" />
        <Table.Column
          title="Your Cost Per Month"
          dataIndex="yourCostPerMonth"
        />
      </Table>
    </>
  );
};

export default AdditionalServices;
