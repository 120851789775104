import * as React from "react";
import { Content } from "components";
import { GeneralInfo, Subscribers } from "./components";
import styles from "./List.module.css";

const List: React.FC = () => (
  <Content className={styles.wrapper}>
    <GeneralInfo className={styles.generalInfo} />
    <Subscribers />
  </Content>
);

export default List;
