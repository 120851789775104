import * as React from "react";
import { Radio, Select, InputNumber, Button } from "components";
import { Types } from "../../../../duck";

interface DataFilterProps {
  className: string;
  radioClassName: string;
  dataFilter: Types.FilterState["data"] | null;
  onDataFilterSave(newFilter: Partial<Types.FilterState["data"]>): void;
}

const DataFilter: React.FC<DataFilterProps> = ({
  className,
  radioClassName,
  dataFilter,
  onDataFilterSave,
}) => {
  const [type, setType] = React.useState();

  const [dataMB, setDataMB] = React.useState("");
  const [categoryIds, setCategoryIds] = React.useState<string[]>([]);

  const [overCategoryIds, setOverCategoryIds] = React.useState<string[]>([]);

  const submitDisabled =
    !type ||
    (type === "specify"
      ? dataMB === "" && categoryIds.length === 0
      : overCategoryIds.length === 0);

  const onSubmit = () => {
    if (type === "included") {
      onDataFilterSave({ included: { categoryIds: overCategoryIds } });
    } else {
      onDataFilterSave({ over: { categoryIds, amountMB: dataMB } });
    }

    setDataMB("");
    setCategoryIds([]);
    setOverCategoryIds([]);
  };

  const excludedIncludedIds = dataFilter?.included?.categoryIds || [];
  const excludedOverIds = dataFilter?.over?.categoryIds || [];

  return (
    <div className={className}>
      <Radio.Group
        value={type}
        name="subData"
        onChange={(event) => setType(event.target.value)}
        className={radioClassName}
      >
        <Radio value="included">Over Included Data</Radio>
        {type === "included" && (
          <Select
            placeholder="Select Categories"
            mode="multiple"
            options={[{ label: "3G/LTE Data", value: "1" }].filter(
              (val) => !excludedIncludedIds.includes(val.value)
            )}
            value={overCategoryIds}
            onChange={setOverCategoryIds}
          />
        )}
        <Radio value="specify">Over Specified Number of Data</Radio>
        {type === "specify" && (
          <>
            <InputNumber
              placeholder="Data Amount (MB)"
              style={{ marginBottom: 8, width: "100%" }}
              value={dataMB}
              onChange={setDataMB}
            />
            <Select
              placeholder="Select Categories"
              mode="multiple"
              options={[{ label: "3G/LTE Data", value: "1" }].filter(
                (opt) => !excludedOverIds.includes(opt.value)
              )}
              value={categoryIds}
              onChange={setCategoryIds}
            />
          </>
        )}
      </Radio.Group>
      <Button
        onClick={onSubmit}
        type="primary"
        size="small"
        style={{ marginTop: 8 }}
        disabled={submitDisabled}
      >
        Add
      </Button>
    </div>
  );
};

export default DataFilter;
