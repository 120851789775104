import * as React from "react";
import { Typography, Row, Space } from "components";
import { Filter, ConfigureLayout, Search, Export } from "./components";

const TopBar: React.FC = () => {
  return (
    <Row justify="space-between">
      <Typography size="24">Subscribers</Typography>
      <Space size={24} direction="horizontal">
        <Filter />
        <ConfigureLayout />
        <Search />
        <Export />
      </Space>
    </Row>
  );
};

export default TopBar;
