export const COLUMN_OPTIONS = [
  { id: "msisdn", name: "MDN" },
  { id: "deviceName", name: "Device" },
  { id: "pricePlan", name: "Plan" },
  { id: "iccid", name: "ICCID" },
  { id: "imei", name: "IMEI" },
  { id: "dataUsed", name: "Data Used" },
  { id: "status", name: "Status" },
  { id: "billingPeriodStart", name: "Contract Start Date" },
  { id: "billingPeriodEnd", name: "Contract End Date" },
];

export const DEFAULT_COLUMN_IDS = [
  "msisdn",
  "imei",
  "iccid",
  "deviceName",
  "pricePlan",
  "status",
  "dataUsed",
];
