import * as React from "react";
import { RouterSwitch, Route, Redirect } from "components";
import { Login, OTP } from "./components";
import styles from "./Auth.module.css";

const Auth: React.FC = () => {
  const [data, setData] = React.useState<{
    method: "phone" | "email";
    value: string;
  } | null>(null);

  return (
    <div className={styles.wrapper}>
      <RouterSwitch>
        <Route path="/login" render={() => <Login onSuccess={setData} />} />
        {data && (
          <Route
            path="/passcode"
            render={() => <OTP {...data} onReset={() => setData(null)} />}
          />
        )}
        <Redirect to="/login" />
      </RouterSwitch>
    </div>
  );
};

export default Auth;
