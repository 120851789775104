import * as React from "react";
import { Button, Row, Space, Typography } from "components";
import Dots from "../dots";
import styles from "./SelectType.module.css";

interface SelectTypeProps {
  onSelect(type: string): void;
}

const SelectType: React.FC<SelectTypeProps> = ({ onSelect }) => {
  return (
    <>
      <Typography size="24" className={styles.text}>
        Bulk Services
      </Typography>
      <Typography className={styles.text}>
        Please choose the bulk process you would like to start:
      </Typography>

      <Row justify="center">
        <Space direction="vertical" size={16} className={styles.buttons}>
          <Button type="primary" ghost onClick={() => onSelect("activate")}>
            Bulk Activation
          </Button>
          <Button type="primary" ghost onClick={() => onSelect("simSwap")}>
            Bulk SIM Swap
          </Button>
          <Button type="primary" ghost onClick={() => onSelect("suspend")}>
            Bulk Suspend
          </Button>
          <Button type="primary" ghost onClick={() => onSelect("restore")}>
            Bulk Restore
          </Button>
        </Space>
      </Row>

      <Dots activeIndex={1} />
    </>
  );
};

export default SelectType;
