import * as React from "react";
import { useParams, useSelector } from "hooks";
import { Card, Space, Skeleton, Row, Typography } from "components";
import styles from "./MainInfo.module.css";

interface MainInfoProps {
  loading: boolean;
}

const MainInfo: React.FC<MainInfoProps> = ({ loading }) => {
  const { id } = useParams<{ id: string }>();
  const data = useSelector((state) => state.mock[id]);

  return (
    <Space direction="vertical" size={32} className={styles.wrapper}>
      <Card
        title={
          <Typography size="16" bold>
            Device
          </Typography>
        }
      >
        {loading ? (
          <Skeleton active />
        ) : (
          <Space direction="vertical" size={8} style={{ width: "100%" }}>
            <Row justify="space-between">
              <Typography>Device Manufacture</Typography>
              <Typography bold>{data.deviceManufacturer}</Typography>
            </Row>

            <Row justify="space-between">
              <Typography>Device Name</Typography>
              <Typography bold>{data.deviceName}</Typography>
            </Row>

            <Row justify="space-between">
              <Typography>Device SKU</Typography>
              <Typography bold>{data.deviceSKU}</Typography>
            </Row>

            <Row justify="space-between">
              <Typography>IMEI</Typography>
              <Typography bold>{data.imei}</Typography>
            </Row>

            <Row justify="space-between">
              <Typography>ICCID</Typography>
              <Typography bold>{data.iccid}</Typography>
            </Row>
          </Space>
        )}
      </Card>
      <Card
        title={
          <Typography size="16" bold>
            Plan
          </Typography>
        }
      >
        {loading ? (
          <Skeleton active />
        ) : (
          <Space direction="vertical" size={8} style={{ width: "100%" }}>
            <Row justify="space-between">
              <Typography>Price Plan</Typography>
              <Typography bold>{data.pricePlan}</Typography>
            </Row>

            <Row justify="space-between">
              <Typography>Price Code</Typography>
              <Typography bold>{data.priceCode}</Typography>
            </Row>

            <Row justify="space-between">
              <Typography>Price Type</Typography>
              <Typography bold>{data.priceType}</Typography>
            </Row>

            <Row justify="space-between">
              <Typography>Price MRC</Typography>
              <Typography bold>{data.priceMRC}</Typography>
            </Row>

            <Row justify="space-between">
              <Typography>Auto Pay</Typography>
              <Typography bold>{data.autoPay}</Typography>
            </Row>
          </Space>
        )}
      </Card>

      <Card
        title={
          <Typography size="16" bold>
            Billing Period
          </Typography>
        }
      >
        {loading ? (
          <Skeleton active />
        ) : (
          <Space direction="vertical" size={8} style={{ width: "100%" }}>
            <Row justify="space-between">
              <Typography>Billing Period Start Date</Typography>
              <Typography bold>{data.billingPeriodStart}</Typography>
            </Row>

            <Row justify="space-between">
              <Typography>Billing Period End Date</Typography>
              <Typography bold>{data.billingPeriodEnd}</Typography>
            </Row>

            <Row justify="space-between">
              <Typography>Billing Sequence Number</Typography>
              <Typography bold>{data.billingSequenceNumber}</Typography>
            </Row>
          </Space>
        )}
      </Card>
      <Card
        title={
          <Typography size="16" bold>
            Usage
          </Typography>
        }
      >
        {loading ? (
          <Skeleton active />
        ) : (
          <Space direction="vertical" size={8} style={{ width: "100%" }}>
            <Row justify="space-between">
              <Typography>Data Usage</Typography>
              <Typography bold>{data.dataUsage}</Typography>
            </Row>
          </Space>
        )}
      </Card>
    </Space>
  );
};

export default MainInfo;
