import * as React from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Typography, Row, Button } from "components";
import { Types } from "../../duck";
import styles from "./SelectedFilters.module.css";

interface SelectedFiltersProps {
  filter: Types.FilterState | null;
  setFilter: React.Dispatch<React.SetStateAction<Types.FilterState | null>>;
}

const SelectedFilters: React.FC<SelectedFiltersProps> = ({
  filter,
  setFilter,
}) => {
  const hasFilters = !!filter && !!filter?.data;

  return (
    <>
      <Typography size="16">Selected Filters</Typography>
      <div className={styles.selectedFilters}>
        {hasFilters ? (
          <>
            <Typography bold>337578469 - Edward Jones</Typography>
            {!!filter?.data.over && (
              <Row>
                <Button
                  onClick={() => {
                    setFilter(null);
                  }}
                  icon={<CloseOutlined />}
                  size="small"
                  danger
                  style={{ marginRight: 4, border: "none" }}
                />
                <Typography>
                  Data Over {filter.data.over.amountMB} MB
                </Typography>
                &nbsp;
                <Typography bold>3G/LTE Data</Typography>
              </Row>
            )}
          </>
        ) : null}
      </div>
    </>
  );
};

export default SelectedFilters;
