import * as React from "react";

const EmailIcon: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_113:9507)">
      <path
        d="M15.9082 12.123L23.9992 17.238V6.79199L15.9082 12.123Z"
        fill="white"
      />
      <path d="M0 6.79199V17.238L8.091 12.123L0 6.79199Z" fill="white" />
      <path
        d="M22.4999 3.75H1.49992C0.751422 3.75 0.157422 4.308 0.0449219 5.0265L11.9999 12.903L23.9549 5.0265C23.8424 4.308 23.2484 3.75 22.4999 3.75Z"
        fill="white"
      />
      <path
        d="M14.5358 13.0288L12.4133 14.4268C12.2873 14.5093 12.1448 14.5498 12.0008 14.5498C11.8568 14.5498 11.7143 14.5093 11.5883 14.4268L9.46583 13.0273L0.0488281 18.9838C0.164328 19.6963 0.755328 20.2498 1.50083 20.2498H22.5008C23.2463 20.2498 23.8373 19.6963 23.9528 18.9838L14.5358 13.0288Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_113:9507">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default EmailIcon;
