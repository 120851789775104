import * as React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Spin } from "components";
import { routes } from "configs";
import { Manage, Activate, Auth, Account } from "pages";
import AppContext from "../app-provider/context";
import { Header } from "./components";

const Layout: React.FC = () => {
  const [{ loading, loggedIn }] = React.useContext(AppContext);

  if (loading) {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin />
      </div>
    );
  }

  if (!loggedIn) {
    return <Auth />;
  }

  return (
    <>
      <Header />
      <Switch>
        <Route path={routes.manage} component={Manage} />
        <Route path={routes.activate} component={Activate} />
        <Route path={routes.account} component={Account} />
        <Redirect to={routes.manage} />
      </Switch>
    </>
  );
};

export default Layout;
